.keyboardContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.keyboard {
  height: 140px;
  width: 468px;
}

@media screen and (max-width: 468px) {
  .keyboard {
    width: 100%;
  }
}

.ctrlKeys {
  background: rgb(255, 0, 0, 0.7) !important;
  color: white;
  min-width: 60px;
}

.keyL {
  margin-right: 12px !important;
}

.keyLeft {
  margin-left: 12px;
}
